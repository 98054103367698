// @generated by protoc-gen-connect-es v0.8.4 with parameter "target=ts"
// @generated from file setting/v1/setting_api.proto (package setting.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddCameraRequest, AddCameraResponse, DeleteCameraRequest, DeleteCameraResponse, GetSettingRequest, GetSettingResponse, ListCamerasRequest, ListCamerasResponse, UnLockCameraRequest, UnLockCameraResponse, UpdateSettingRequest, UpdateSettingResponse } from "./setting_api_pb.js";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * SettingAPI service
 *
 * @generated from service setting.v1.SettingAPI
 */
export const SettingAPI = {
  typeName: "setting.v1.SettingAPI",
  methods: {
    /**
     * @generated from rpc setting.v1.SettingAPI.GetSetting
     */
    getSetting: {
      name: "GetSetting",
      I: GetSettingRequest,
      O: GetSettingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc setting.v1.SettingAPI.UpdateSetting
     */
    updateSetting: {
      name: "UpdateSetting",
      I: UpdateSettingRequest,
      O: UpdateSettingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc setting.v1.SettingAPI.ListCameras
     */
    listCameras: {
      name: "ListCameras",
      I: ListCamerasRequest,
      O: ListCamerasResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc setting.v1.SettingAPI.AddCamera
     */
    addCamera: {
      name: "AddCamera",
      I: AddCameraRequest,
      O: AddCameraResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc setting.v1.SettingAPI.DeleteCamera
     */
    deleteCamera: {
      name: "DeleteCamera",
      I: DeleteCameraRequest,
      O: DeleteCameraResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc setting.v1.SettingAPI.UnLockCamera
     */
    unLockCamera: {
      name: "UnLockCamera",
      I: UnLockCameraRequest,
      O: UnLockCameraResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

