import { ChakraProvider } from "@chakra-ui/react";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import popoverTheme from "Theme/PopoverTheme";
import { TransportProvider } from "@bufbuild/connect-query";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Main } from "Main";
import { transport } from "clients";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      staleTime: Infinity,
    },
  },
});

export const App = () => (
  <ChakraProvider theme={popoverTheme}>
    <TransportProvider transport={transport}>
      <QueryClientProvider client={queryClient}>
        <Main />
      </QueryClientProvider>
    </TransportProvider>
  </ChakraProvider>
);
