// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file stream/v1/stream_api.proto (package stream.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum stream.v1.StreamState
 */
export const StreamState = proto3.makeEnum(
  "stream.v1.StreamState",
  [
    {no: 0, name: "STREAM_STATE_INVALID", localName: "INVALID"},
    {no: 1, name: "STREAM_STATE_READY", localName: "READY"},
    {no: 2, name: "STREAM_STATE_PAUSED", localName: "PAUSED"},
    {no: 3, name: "STREAM_STATE_ACTIVE", localName: "ACTIVE"},
    {no: 4, name: "STREAM_STATE_DONE", localName: "DONE"},
  ],
);

/**
 * @generated from enum stream.v1.CommandType
 */
export const CommandType = proto3.makeEnum(
  "stream.v1.CommandType",
  [
    {no: 0, name: "COMMAND_TYPE_INVALID", localName: "INVALID"},
    {no: 1, name: "COMMAND_TYPE_START", localName: "START"},
    {no: 2, name: "COMMAND_TYPE_PAUSE", localName: "PAUSE"},
    {no: 3, name: "COMMAND_TYPE_RESUME", localName: "RESUME"},
    {no: 4, name: "COMMAND_TYPE_STOP", localName: "STOP"},
  ],
);

/**
 * @generated from message stream.v1.Stream
 */
export const Stream = proto3.makeMessageType(
  "stream.v1.Stream",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(StreamState) },
    { no: 4, name: "thumbnail_file", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "started_at", kind: "message", T: Timestamp },
    { no: 6, name: "ended_at", kind: "message", T: Timestamp },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
    { no: 8, name: "updated_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message stream.v1.ListStreamsRequest
 */
export const ListStreamsRequest = proto3.makeMessageType(
  "stream.v1.ListStreamsRequest",
  [],
);

/**
 * @generated from message stream.v1.ListStreamsResponse
 */
export const ListStreamsResponse = proto3.makeMessageType(
  "stream.v1.ListStreamsResponse",
  () => [
    { no: 1, name: "streams", kind: "message", T: Stream, repeated: true },
  ],
);

/**
 * @generated from message stream.v1.GetStreamRequest
 */
export const GetStreamRequest = proto3.makeMessageType(
  "stream.v1.GetStreamRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stream.v1.GetStreamResponse
 */
export const GetStreamResponse = proto3.makeMessageType(
  "stream.v1.GetStreamResponse",
  () => [
    { no: 1, name: "stream", kind: "message", T: Stream },
  ],
);

/**
 * @generated from message stream.v1.DeleteStreamRequest
 */
export const DeleteStreamRequest = proto3.makeMessageType(
  "stream.v1.DeleteStreamRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "delete_files", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message stream.v1.DeleteStreamResponse
 */
export const DeleteStreamResponse = proto3.makeMessageType(
  "stream.v1.DeleteStreamResponse",
  [],
);

/**
 * @generated from message stream.v1.CreateStreamRequest
 */
export const CreateStreamRequest = proto3.makeMessageType(
  "stream.v1.CreateStreamRequest",
  () => [
    { no: 1, name: "camera", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stream.v1.CreateStreamResponse
 */
export const CreateStreamResponse = proto3.makeMessageType(
  "stream.v1.CreateStreamResponse",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message stream.v1.Command
 */
export const Command = proto3.makeMessageType(
  "stream.v1.Command",
  () => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(CommandType) },
    { no: 2, name: "frame_no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message stream.v1.SendStreamCommandsRequest
 */
export const SendStreamCommandsRequest = proto3.makeMessageType(
  "stream.v1.SendStreamCommandsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "command", kind: "message", T: Command },
  ],
);

/**
 * @generated from message stream.v1.SendStreamCommandsResponse
 */
export const SendStreamCommandsResponse = proto3.makeMessageType(
  "stream.v1.SendStreamCommandsResponse",
  [],
);

