// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file user/v1/user_api.proto (package user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { User } from "../../base/v1/user_pb.js";
import { UploadMedia } from "../../base/v1/media_pb.js";
import { RoleType } from "../../base/v1/role_pb.js";

/**
 * @generated from message user.v1.GetProfileRequest
 */
export const GetProfileRequest = proto3.makeMessageType(
  "user.v1.GetProfileRequest",
  [],
);

/**
 * @generated from message user.v1.GetProfileResponse
 */
export const GetProfileResponse = proto3.makeMessageType(
  "user.v1.GetProfileResponse",
  () => [
    { no: 1, name: "user", kind: "message", T: User },
  ],
);

/**
 * @generated from message user.v1.UpdateProfileRequest
 */
export const UpdateProfileRequest = proto3.makeMessageType(
  "user.v1.UpdateProfileRequest",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "avatar", kind: "message", T: UploadMedia },
  ],
);

/**
 * @generated from message user.v1.UpdateProfileResponse
 */
export const UpdateProfileResponse = proto3.makeMessageType(
  "user.v1.UpdateProfileResponse",
  [],
);

/**
 * @generated from message user.v1.ListAllUsersRequest
 */
export const ListAllUsersRequest = proto3.makeMessageType(
  "user.v1.ListAllUsersRequest",
  () => [
    { no: 1, name: "roles", kind: "enum", T: proto3.getEnumType(RoleType), repeated: true },
  ],
);

/**
 * @generated from message user.v1.ListAllUsersResponse
 */
export const ListAllUsersResponse = proto3.makeMessageType(
  "user.v1.ListAllUsersResponse",
  () => [
    { no: 1, name: "users", kind: "message", T: User, repeated: true },
  ],
);

/**
 * @generated from message user.v1.RequestAccountDeleteRequest
 */
export const RequestAccountDeleteRequest = proto3.makeMessageType(
  "user.v1.RequestAccountDeleteRequest",
  [],
);

/**
 * @generated from message user.v1.RequestAccountDeleteResponse
 */
export const RequestAccountDeleteResponse = proto3.makeMessageType(
  "user.v1.RequestAccountDeleteResponse",
  [],
);

/**
 * @generated from message user.v1.CreateUserRequest
 */
export const CreateUserRequest = proto3.makeMessageType(
  "user.v1.CreateUserRequest",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message user.v1.CreateUserResponse
 */
export const CreateUserResponse = proto3.makeMessageType(
  "user.v1.CreateUserResponse",
  [],
);

/**
 * @generated from message user.v1.DeleteUserRequest
 */
export const DeleteUserRequest = proto3.makeMessageType(
  "user.v1.DeleteUserRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message user.v1.DeleteUserResponse
 */
export const DeleteUserResponse = proto3.makeMessageType(
  "user.v1.DeleteUserResponse",
  [],
);

/**
 * @generated from message user.v1.SetUserActiveStatusRequest
 */
export const SetUserActiveStatusRequest = proto3.makeMessageType(
  "user.v1.SetUserActiveStatusRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message user.v1.SetUserActiveStatusResponse
 */
export const SetUserActiveStatusResponse = proto3.makeMessageType(
  "user.v1.SetUserActiveStatusResponse",
  [],
);

/**
 * @generated from message user.v1.ResetUserPasswordRequest
 */
export const ResetUserPasswordRequest = proto3.makeMessageType(
  "user.v1.ResetUserPasswordRequest",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "new_password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message user.v1.ResetUserPasswordResponse
 */
export const ResetUserPasswordResponse = proto3.makeMessageType(
  "user.v1.ResetUserPasswordResponse",
  [],
);

