import { Box, Center, Stack, Text } from "@chakra-ui/react";
import { CircularProgress } from "@chakra-ui/react";
import { VStack } from "@chakra-ui/react";
import React from "react";

interface LoadingProps {
  size?: number;
  color?: string;
  text?: string;
}

export const Loading = ({
  size = 120,
  color = "messenger.400",
  text = "Loading...",
}: LoadingProps) => {
  return (
    <VStack justify="center" align="stretch" h="45vh">
      <Box w="100%">
        <Stack>
          <Center>
            <Text>{text}</Text>
          </Center>
          <Center>
            <CircularProgress isIndeterminate color={color} size={size} />
          </Center>
        </Stack>
      </Box>
    </VStack>
  );
};
