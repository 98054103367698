import React from "react";
import { ReactNode } from "react";

export const isPublic =
  "true".toLowerCase() ===
  (process.env.REACT_APP_IS_PUBLIC ?? "").toLowerCase();

export const ForPublicWrapper = ({
  children,
  hideInPublic = true,
  onlyInPublic = false,
}: {
  children: ReactNode;
  hideInPublic?: boolean;
  onlyInPublic?: boolean;
}) => {
  if (onlyInPublic && !isPublic) {
    return <></>;
  }
  if (isPublic && hideInPublic && !onlyInPublic) {
    return <></>;
  }

  return <>{children}</>;
};
