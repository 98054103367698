// @generated by protoc-gen-connect-query v0.2.0 with parameter "target=ts"
// @generated from file user/v1/user_api.proto (package user.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { createQueryService } from "@bufbuild/connect-query";
import { MethodKind } from "@bufbuild/protobuf";
import { CreateUserRequest, CreateUserResponse, DeleteUserRequest, DeleteUserResponse, GetProfileRequest, GetProfileResponse, ListAllUsersRequest, ListAllUsersResponse, RequestAccountDeleteRequest, RequestAccountDeleteResponse, ResetUserPasswordRequest, ResetUserPasswordResponse, SetUserActiveStatusRequest, SetUserActiveStatusResponse, UpdateProfileRequest, UpdateProfileResponse } from "./user_api_pb.js";

export const typeName = "user.v1.UserAPI";

/**
 * @generated from rpc user.v1.UserAPI.GetProfile
 */
export const getProfile = createQueryService({
  service: {
    methods: {
      getProfile: {
        name: "GetProfile",
        kind: MethodKind.Unary,
        I: GetProfileRequest,
        O: GetProfileResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).getProfile;

/**
 * @generated from rpc user.v1.UserAPI.UpdateProfile
 */
export const updateProfile = createQueryService({
  service: {
    methods: {
      updateProfile: {
        name: "UpdateProfile",
        kind: MethodKind.Unary,
        I: UpdateProfileRequest,
        O: UpdateProfileResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).updateProfile;

/**
 * @generated from rpc user.v1.UserAPI.RequestAccountDelete
 */
export const requestAccountDelete = createQueryService({
  service: {
    methods: {
      requestAccountDelete: {
        name: "RequestAccountDelete",
        kind: MethodKind.Unary,
        I: RequestAccountDeleteRequest,
        O: RequestAccountDeleteResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).requestAccountDelete;

/**
 * @generated from rpc user.v1.UserAPI.ListAllUsers
 */
export const listAllUsers = createQueryService({
  service: {
    methods: {
      listAllUsers: {
        name: "ListAllUsers",
        kind: MethodKind.Unary,
        I: ListAllUsersRequest,
        O: ListAllUsersResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).listAllUsers;

/**
 * @generated from rpc user.v1.UserAPI.CreateUser
 */
export const createUser = createQueryService({
  service: {
    methods: {
      createUser: {
        name: "CreateUser",
        kind: MethodKind.Unary,
        I: CreateUserRequest,
        O: CreateUserResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).createUser;

/**
 * @generated from rpc user.v1.UserAPI.DeleteUser
 */
export const deleteUser = createQueryService({
  service: {
    methods: {
      deleteUser: {
        name: "DeleteUser",
        kind: MethodKind.Unary,
        I: DeleteUserRequest,
        O: DeleteUserResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).deleteUser;

/**
 * @generated from rpc user.v1.UserAPI.SetUserActiveStatus
 */
export const setUserActiveStatus = createQueryService({
  service: {
    methods: {
      setUserActiveStatus: {
        name: "SetUserActiveStatus",
        kind: MethodKind.Unary,
        I: SetUserActiveStatusRequest,
        O: SetUserActiveStatusResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).setUserActiveStatus;

/**
 * @generated from rpc user.v1.UserAPI.ResetUserPassword
 */
export const resetUserPassword = createQueryService({
  service: {
    methods: {
      resetUserPassword: {
        name: "ResetUserPassword",
        kind: MethodKind.Unary,
        I: ResetUserPasswordRequest,
        O: ResetUserPasswordResponse,
      },
    },
    typeName: "user.v1.UserAPI",
  },
}).resetUserPassword;
