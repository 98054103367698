// @generated by protoc-gen-es v1.2.0 with parameter "target=js+dts"
// @generated from file base/v1/location.proto (package base.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * Location represents a coordinate in a plane
 *
 * @generated from message base.v1.Location
 */
export const Location = proto3.makeMessageType(
  "base.v1.Location",
  () => [
    { no: 1, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * Court represents the coordinates of court in a plane, generally a frame
 *
 * @generated from message base.v1.Court
 */
export const Court = proto3.makeMessageType(
  "base.v1.Court",
  () => [
    { no: 1, name: "top_left", kind: "message", T: Location },
    { no: 2, name: "top_right", kind: "message", T: Location },
    { no: 3, name: "middle_left", kind: "message", T: Location },
    { no: 4, name: "middle_right", kind: "message", T: Location },
    { no: 5, name: "bottom_left", kind: "message", T: Location },
    { no: 6, name: "bottom_right", kind: "message", T: Location },
    { no: 7, name: "middle", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ],
);

/**
 * Box represents coordinates of a rectagular/square object
 *
 * @generated from message base.v1.Box
 */
export const Box = proto3.makeMessageType(
  "base.v1.Box",
  () => [
    { no: 1, name: "top_left", kind: "message", T: Location },
    { no: 2, name: "top_right", kind: "message", T: Location },
    { no: 3, name: "bottom_left", kind: "message", T: Location },
    { no: 4, name: "bottom_right", kind: "message", T: Location },
    { no: 5, name: "middle", kind: "message", T: Location },
    { no: 6, name: "middle_left", kind: "message", T: Location },
    { no: 7, name: "middle_right", kind: "message", T: Location },
    { no: 8, name: "middle_top", kind: "message", T: Location },
    { no: 9, name: "middle_bottom", kind: "message", T: Location },
  ],
);

